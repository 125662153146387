import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        auth: false,
      },
    },
    {
      path: '/qr-code/:code',
      name: 'qr-code',
      component: () => import('@/views/QRcode.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/Privacy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/user-all',
      name: 'user-all',
      component: () => import('@/views/user/user-all/UserAll.vue'),
      meta: {
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'All User',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/user-add',
      name: 'user-add',
      component: () => import('@/views/user/user-add/UserAdd.vue'),
      meta: {
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'Add User',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/user-edit/:id',
      name: 'user-edit',
      component: () => import('@/views/user/user-edit/UserEdit.vue'),
      meta: {
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'Edit User',
            active: true,
          },
        ],
        auth: true,
        params: true,
      },
    },
    {
      path: '/church-all',
      name: 'church-all',
      component: () => import('@/views/church/church-all/ChurchAll.vue'),
      meta: {
        pageTitle: 'Church',
        breadcrumb: [
          {
            text: 'All Church',
            active: true,
          },
        ],
      },
    },
    {
      path: '/church-add',
      name: 'church-add',
      component: () => import('@/views/church/church-add/ChurchAdd.vue'),
      meta: {
        pageTitle: 'Church',
        breadcrumb: [
          {
            text: 'Add Church',
            active: true,
          },
        ],
      },
    },
    {
      path: '/church-edit/:id',
      name: 'church-edit',
      component: () => import('@/views/church/church-edit/ChurchEdit.vue'),
      meta: {
        pageTitle: 'Church',
        breadcrumb: [
          {
            text: 'Edit Church',
            active: true,
          },
        ],
      },
    },
    {
      path: '/synod-all',
      name: 'synod-all',
      component: () => import('@/views/synod/synod-all/SynodAll.vue'),
      meta: {
        pageTitle: 'Synod',
        breadcrumb: [
          {
            text: 'All Synod',
            active: true,
          },
        ],
      },
    },
    {
      path: '/synod-add',
      name: 'synod-add',
      component: () => import('@/views/synod/synod-add/SynodAdd.vue'),
      meta: {
        pageTitle: 'Synod',
        breadcrumb: [
          {
            text: 'Add Synod',
            active: true,
          },
        ],
      },
    },
    {
      path: '/synod-edit/:id',
      name: 'synod-edit',
      component: () => import('@/views/synod/synod-edit/SynodEdit.vue'),
      meta: {
        pageTitle: 'Synod',
        breadcrumb: [
          {
            text: 'Edit Synod',
            active: true,
          },
        ],
      },
    },
    {
      path: '/department-all',
      name: 'department-all',
      component: () => import('@/views/department/department-all/DepartmentAll.vue'),
      meta: {
        pageTitle: 'Department',
        breadcrumb: [
          {
            text: 'All Department',
            active: true,
          },
        ],
      },
    },
    {
      path: '/department-add',
      name: 'department-add',
      component: () => import('@/views/department/department-add/DepartmentAdd.vue'),
      meta: {
        pageTitle: 'Department',
        breadcrumb: [
          {
            text: 'Add Department',
            active: true,
          },
        ],
      },
    },
    {
      path: '/department-edit/:id',
      name: 'department-edit',
      component: () => import('@/views/department/department-edit/DepartmentEdit.vue'),
      meta: {
        pageTitle: 'Department',
        breadcrumb: [
          {
            text: 'Edit Department',
            active: true,
          },
        ],
      },
    },
    {
      path: '/position-all',
      name: 'position-all',
      component: () => import('@/views/position/position-all/PositionAll.vue'),
      meta: {
        pageTitle: 'Position',
        breadcrumb: [
          {
            text: 'All Position',
            active: true,
          },
        ],
      },
    },
    {
      path: '/position-add',
      name: 'position-add',
      component: () => import('@/views/position/position-add/PositionAdd.vue'),
      meta: {
        pageTitle: 'Position',
        breadcrumb: [
          {
            text: 'Add Position',
            active: true,
          },
        ],
      },
    },
    {
      path: '/position-edit/:id',
      name: 'position-edit',
      component: () => import('@/views/position/position-edit/PositionEdit.vue'),
      meta: {
        pageTitle: 'Position',
        breadcrumb: [
          {
            text: 'Edit Position',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event-all',
      name: 'event-all',
      component: () => import('@/views/event/event-all/EventAll.vue'),
      meta: {
        pageTitle: 'Event',
        breadcrumb: [
          {
            text: 'All Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event-add',
      name: 'event-add',
      component: () => import('@/views/event/event-add/EventAdd.vue'),
      meta: {
        pageTitle: 'Event',
        breadcrumb: [
          {
            text: 'Add Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/event-edit/:id',
      name: 'event-edit',
      component: () => import('@/views/event/event-edit/EventEdit.vue'),
      meta: {
        pageTitle: 'Event',
        breadcrumb: [
          {
            text: 'Edit Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-all',
      name: 'category-all',
      component: () => import('@/views/category/category-all/CategoryAll.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'All Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-add',
      name: 'category-add',
      component: () => import('@/views/category/category-add/CategoryAdd.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Add Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/category-edit/:id',
      name: 'category-edit',
      component: () => import('@/views/category/category-edit/CategoryEdit.vue'),
      meta: {
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Edit Category',
            active: true,
            params: true,
          },
        ],
      },
    },
    {
      path: '/article-all',
      name: 'article-all',
      component: () => import('@/views/article/article-all/ArticleAll.vue'),
      meta: {
        pageTitle: 'Article',
        breadcrumb: [
          {
            text: 'All Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/article-add',
      name: 'article-add',
      component: () => import('@/views/article/article-add/ArticleAdd.vue'),
      meta: {
        pageTitle: 'Article',
        breadcrumb: [
          {
            text: 'Add Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/article-edit/:id',
      name: 'article-edit',
      component: () => import('@/views/article/article-edit/ArticleEdit.vue'),
      meta: {
        pageTitle: 'Article',
        breadcrumb: [
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slider-all',
      name: 'slider-all',
      component: () => import('@/views/settings/slider-all/SliderAll.vue'),
      meta: {
        pageTitle: 'Slider',
        breadcrumb: [
          {
            text: 'All Slider',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slider-add',
      name: 'slider-add',
      component: () => import('@/views/settings/slider-add/SliderAdd.vue'),
      meta: {
        pageTitle: 'Slider',
        breadcrumb: [
          {
            text: 'Add Slider',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slider-edit/:id',
      name: 'slider-edit',
      component: () => import('@/views/settings/slider-edit/SliderEdit.vue'),
      meta: {
        pageTitle: 'Slider',
        breadcrumb: [
          {
            text: 'Edit Slider',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem('user')
//   console.log('login', loggedIn)
//   if (to.matched.some(record => record.meta.auth) && !loggedIn) {
//     next('/login')
//     return
//   }
//   next()
// })

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  if (to.name !== 'login' && !loggedIn && to.name !== 'register') next({ name: 'login' })
  else next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
