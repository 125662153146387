import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueRouter from 'vue-router'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(VueRouter)
Vue.use(Vuex)

axios.defaults.baseURL = 'https://apistaging.gerejaku.net/api/v1' // PROD
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1' // DEV

export default new Vuex.Store({
  state: {
    user: null,
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
  },

  mutations: {
    setUserData() {
      axios
        .get('/me')
        .then(res => {
          this.userData = res.data.data
          const stringUser = JSON.stringify(this.userData)
          const encodedUserData = Buffer.from(stringUser).toString('base64')
          localStorage.setItem('user', encodedUserData)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },

    setToken(state, token) {
      localStorage.setItem('token', JSON.stringify(token))
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },

    clearToken() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
  },

  actions: {
    login({ commit }, credentials) {
      return axios
        .post('/login', credentials)
        .then(({ data }) => {
          commit('setToken', data.data.access_token)
          commit('setUserData')
        })
    },

    logout({ commit }) {
      commit('clearToken')
    },
  },

  getters: {
    isLogged: state => !!state.user,
  },

  strict: process.env.DEV,
})
